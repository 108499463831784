import React from "react"
import LayoutCourse from "../../../layouts/course"
import Seo from "../../../components/seo"
import Heading from "../../../components/heading"
import Stack from "../../../components/stack"
import Box from "../../../components/box"
import CourseFooterNext from "../../../components/course-footer-next"
import Columns from "../../../components/columns"
import Column from "../../../components/column"
import Text from "../../../components/text"
import { getCourseNavigation } from "../../../store/courses"
import InfoBox from "../../../components/info-box"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext
          title="Bist du bereit?"
          label="Los Geht's"
          to="/kurse/haelfte-des-lebens/01-zwei-haelften"
        />
      }
      navigation={navigation}
    >
      <Seo title="Hölderlins Meisterwerk ›Hälfte des Lebens‹" />
      <Box>
        <Stack>
          <Columns
            alignY="center"
            alignX="space-between"
            collapse={[true, false]}
          >
            <Column>
              <Text sans>Für alle Interessierten</Text>
            </Column>
          </Columns>
          <Heading as="h2" level={2}>
            Hölderlins Meisterwerk ›Hälfte des Lebens‹
          </Heading>
          <InfoBox title="Um was geht es?">
            ›Hälfte des Lebens‹ ist das bekannteste und für viele auch das
            schönste und berührendste Gedicht Friedrich Hölderlins. Im Jahr 1804
            entstanden, wird es heute als merkwürdige Vorwegnahme jener
            schicksalhaften Wende gedeutet, die Hölderlins Leben in zwei Hälften
            teilte. Doch darüber hinaus ist es auch ein kunstvoll gefertigtes
            lyrisches Meisterwerk. Warum? Das findest du in diesem Kurs Schritt
            für Schritt selbst heraus…
          </InfoBox>
          <InfoBox title="Was ist dein Ziel?">
            Auf den ersten Blick wirkt ›Hälfte des Lebens‹ im Vergleich zu
            anderen Gedichten von Hölderlin recht schlicht – ohne viel
            Schnickschnack. Bei der eingehenderen Beschäftigung damit wirst du
            jedoch entdecken, wie viele Raffinessen Hölderlin in den wenigen
            Zeilen versteckt hat. Du siehst hinter die Fassade des fertigen
            Gedichts und erblickst das Gerüst, nach dem der Dichter seine Wörter
            zu einem formvollendeten Sprachkunstwerk angeordnet hat.
            Anschließend nimmst du das Gedichtmanuskript unter die Lupe und
            erfährst mehr über seine Veröffentlichungsgeschichte.
          </InfoBox>
          <InfoBox title="Was lernst du in diesem Kurs?">
            Du lernst verschiedene Blickwinkel kennen, aus denen man ein Gedicht
            betrachten kann. In der Auseinandersetzung mit Hölderlins Gedicht
            ›Hälfte des Lebens‹ wirst du feststellen, dass ein Gedicht weit mehr
            als eine Ansammlung schöner Wörter und Gedanken sein kann. Du
            untersuchst das Sprachmaterial, aus dem sie zusammengesetzt sind,
            entwickelst einen Blick für die Architektur, nach der sie aufgebaut
            sind, und ein Gehör für die Sprachmelodie, die daraus erwächst.
          </InfoBox>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
